import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { useGlobal } from '../../utility/CTX'
import { Link } from 'react-router-dom'
import { Button, ButtonPill } from '../../layout/components/button'
import { useCallback } from 'react'
import { useSession } from '../../utility/Auth';
import { collection, doc, addDoc, serverTimestamp, getFirestore } from "firebase/firestore"; 







function Feedback() {
    const { theme } = useGlobal();
    const [feedback, setFeedback] = useState('');
    const [type, setType] = useState('General Feedback');
    const [status, setStatus] = useState(null);
    const session = useSession();




    const handleSubmit = useCallback(async (e) => {
        if (session.user) {
            try {
                let db = getFirestore();

                let data = {
                    userId: session.user?.uid,
                    content: feedback,
                    type,
                    timestamp: serverTimestamp(),
                    comments: []
                };

                const colRef = collection(db, 'feedback')
                const docRef = await addDoc(colRef, data);

                setStatus(<Row style={{color: theme.green, margin: 'auto'}}>
                    Feedback submitted successfully!
                </Row>);
                setFeedback('');
                setType('General Feedback');
            } catch (error) {
                setStatus(<Row style={{color: theme.red, margin: 'auto'}}>
                    Feedback submition failed! Please try again.
                </Row>)
            }
        } else {
            setStatus(<Row style={{color: theme.yellow, margin: 'auto'}}>
                Please try again.
            </Row>)
        }
    }, [feedback, session?.user, setStatus, setType, setFeedback]);



    return <Column style={{alignItems: 'center'}}>
        <Helmet>
            <title>Feedback - Gateway Forge</title>
            <meta name="description" content="Let us know how things have been going, and if there is anything we can do to improve the platform." />

            <meta property="og:url" content="https://gatewayforge.com/feedback" />
            <meta property="og:title" content="Feedback - Gateway Forge" />
            <meta property="og:description" content="Let us know how things have been going, and if there is anything we can do to improve the platform." />

        </Helmet>

        
        <h1 style={{padding: 20}}>Feedback</h1>
        <Column style={{flexGrow: 1, maxWidth: 800, margin: '0px 40px 40px 40px'}}>

            <p  style={{margin: '20px auto', textAlign: 'center', width: '80%'}}>
                Help shape the future of Gateway Forge! Share your feedback, report issues, 
                or suggest new features. Your input is invaluable in creating the best tools for worldbuilding and storytelling.
            </p>
           

            <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder='How are things going? Anything that could be improved?'
                style={{flexGrow: 1, width: '80%', margin: 'auto', background: theme.bg, borderRadius: 5, padding: '5px 10px', minHeight: 100, resize: 'vertical', border: `1px solid ${theme.border}`, outline: 'none', color: theme.txt_dark, fontFamily: 'Rajdhani', fontSize: 16}} />
        
            <Row style={{justifyContent: 'center', margin: '20px 50px', alignItems: 'start', flexWrap: 'wrap' }}>
                <p  style={{margin: '0px auto 10px auto', textAlign: 'center', width: '80%'}}>
                    After you submit feedback, you are able to monitor updates and responses through 
                    the <Link to='/account?tab=feedback' style={{color: theme.orange}}>Account Page</Link>. We make an effort to address every piece of feedback that we receive.
                </p>

                <Button icon='chevron_right' text='Submit' color={theme.green} onClick={handleSubmit} />
            </Row>

            {status}
        </Column>
        
    
    </Column>
}

export default Feedback